import * as React from "react"

import * as S from "./styled"

import { Whatsapp as WppIcon } from "@styled-icons/boxicons-logos/Whatsapp"

const WppButtonFloat = () => {
  const wppNumber = "08002521600"

  return (
    <S.WppButtonFloat
      href={`https://api.whatsapp.com/send?phone=55${wppNumber}&text=Olá,%20escreva%20uma%20mensagem!`}
      title="Fale conosco pelo whatsapp"
      target="_blank"
      rel="noreferrer"
    >
      <WppIcon className="icon" />
    </S.WppButtonFloat>
  )
}

export default WppButtonFloat
