import styled, { css } from "styled-components"

import { breakpoints, sizes } from "../../styles/breakpoints"

export const Title = styled.h4`
  color: var(--color-primary);
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.1;

  @media ${breakpoints.lessThan(sizes.phone)} {
    font-size: 1.7rem;
  }
`

export const Paragraph = styled.p`
  color: var(--color-grayscale5);
  font-size: 1rem;
  line-height: 1.3;
  max-width: ${props => (props.maxWidth ? props.maxWidth : "100%")};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "0")};

  ${props =>
    props.centered &&
    css`
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    `}

  ${props =>
    props.darken &&
    css`
      color: var(--color-grayscale6);
    `}

    ${props =>
    props.justify &&
    css`
      text-align: justify;
    `}
`
