import styled from "styled-components"

// import { sizes, breakpoints } from "../../styles/breakpoints"

export const Hero = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-grayscale4);
  height: 90px;
`
