import styled, { css } from "styled-components"

import { breakpoints, sizes } from "../../styles/breakpoints"

const sectionThemes = {
  default: css`
    background-color: transparent;
  `,
  primary: css`
    background-color: var(--color-primary);
  `,
  secondary: css`
    background-color: var(--color-secondary);
  `,
}

export const Section = styled.section`
  padding-top: 100px;
  ${props =>
    props.theme ? sectionThemes[props.theme] : sectionThemes["default"]}

  @media ${breakpoints.lessThan(sizes.tablet)} {
    padding-top: 85px;
  }

  @media ${breakpoints.lessThan(sizes.phone)} {
    padding-top: 70px;
  }

  ${props =>
    props.noPadding &&
    css`
      padding: 0 !important;
    `}

  ${props =>
    props.paddingTopBottom &&
    css`
      padding: 100px 0;

      @media ${breakpoints.lessThan(sizes.tablet)} {
        padding-top: 85px 0;
      }

      @media ${breakpoints.lessThan(sizes.phone)} {
        padding-top: 80px 0;
      }
    `}
`

export const SectionHeader = styled.header`
  width: 100%;
  max-width: ${props => (props.maxWidth ? props.maxWidth : "100%")};

  ${props =>
    props.between &&
    css`
      display: flex;
      justify-content: space-between;
    `}

  ${props =>
    props.centered &&
    css`
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    `}
`

export const SectionContent = styled.div`
  margin-top: 35px;
  width: 100%;

  ${props =>
    props.centered &&
    css`
      text-align: center;
    `}

  ${props =>
    props.noMargin &&
    css`
      margin-top: 0;
    `}
`
export const SectionTitle = styled.h2`
  color: var(--color-primary);
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 20px;

  @media ${breakpoints.lessThan(sizes.desktop)} {
    font-size: 2.2rem;
  }
  @media ${breakpoints.lessThan(sizes.phone)} {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  ${props =>
    props.smaller &&
    css`
      font-size: 1.6rem;
      @media ${breakpoints.lessThan(sizes.desktop)} {
        font-size: 1.5rem;
      }
      @media ${breakpoints.lessThan(sizes.phone)} {
        font-size: 1.4rem;
      }
    `}

  ${props =>
    props.medium &&
    css`
      font-size: 1.8rem;
      @media ${breakpoints.lessThan(sizes.desktop)} {
        font-size: 1.7rem;
      }
      @media ${breakpoints.lessThan(sizes.phone)} {
        font-size: 1.6rem;
      }
    `}

  ${props =>
    props.noMargin &&
    css`
      margin-bottom: 0;
      @media ${breakpoints.lessThan(sizes.phone)} {
        margin-bottom: 0;
      }
    `}

  ${props =>
    props.colorInverse &&
    css`
      color: var(--color-primary);
    `}
`
