import * as React from "react"

import Seo from "../components/seo"
import Layout from "../components/Layout"
import SectionBusinessModel from "../components/Section/SectionBusinessModel"
import WppButtonFloat from "../components/Button/WppButtonFloat"

const BusinessModelPage = () => (
  <Layout>
    <Seo title="Modelo de Negócio" />
    <SectionBusinessModel />
    <WppButtonFloat />
  </Layout>
)

export default BusinessModelPage
