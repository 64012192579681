import styled from "styled-components"
import { breakpoints, sizes } from "../../../styles/breakpoints"

export const WppButtonFloat = styled.a`
  z-index: 5000;
  position: fixed;
  right: 30px;
  bottom: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-whatsapp);
  color: var(--color-white);
  border-radius: 50%;
  width: 55px;
  height: 55px;
  box-shadow: 0 0 0 0 rgba(37, 211, 102, 1);
  transform: scale(1);
  animation: pulse 4s infinite;
  transition: all ease-in-out 0.2s;
  @media ${breakpoints.lessThan(sizes.desktop)} {
    width: 65px;
    height: 65px;
    right: 20px;
    bottom: 95px;
  }

  &:hover {
    background-color: var(--color-whatsapp2);
    transform: scale(1.1);
    animation: none;
    transition: all ease-in-out 0.2s;
  }

  .icon {
    width: 35px;
    height: 35px;
    @media ${breakpoints.lessThan(sizes.desktop)} {
      width: 40px;
      height: 40px;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`
