import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"
import { Container } from "../../../styles/container"
import { SectionHeader, SectionContent, SectionTitle } from "../"
import { Paragraph } from "../../Text"
import SectionHero from "../SectionHero"

import * as S from "./styled"

const SectionBusinessModel = () => (
  <S.Section>
    <SectionHeader>
      <SectionHero>
        <Container>
          <SectionTitle as="h1" medium noMargin colorInverse>
            Modelo de negócio
          </SectionTitle>
        </Container>
      </SectionHero>
    </SectionHeader>

    <SectionContent className="s-content">
      <Container>
        <div className="box-content">
          <StaticImage
            className="s-image"
            src="../../../images/handshake.jpg"
            objectFit="cover"
            objectPosition="center"
            alt="Modelo de Negocio"
          />

          <Paragraph darken className="paragraph">
            Concebido através do sonho do Dr. Ruy Molina, de uma sociedade mais
            justa e igualitária, onde os direitos individuais e coletivos sejam
            respeitados e resguardados, independentemente de raça, sexo,
            religião, condição social, ou qualquer outra condição.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Para isso, desenvolveu nosso plano de negócio, com finalidade de
            democratizar o acesso à justiça, de forma ampla e real, eliminando
            as barreiras entre as pessoas que necessitam de serviços jurídicos
            personalizado e de qualidade com a RM Advocacia.
          </Paragraph>
          <Paragraph darken className="paragraph">
            O grande diferencial do nosso negócio, é a facilidade em prestar os
            serviços de advocacia, com agilidade de comunicação, bem como
            permitir o acesso à justiça de todos os cidadãos, inclusive, os que
            se encontram em situação de hipossuficiência, sem a necessidade de
            se deslocar para escritórios físicos, ou qualquer outro lugar. O que
            faz com que a RM Advocacia seja a primeira opção em solução de
            serviços jurídicos do Brasil.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Dessa forma, investimos em avançados sistemas tecnológicos, com
            total interação humana, desenvolvidos justamente com a finalidade de
            permitir o fácil acesso dos clientes, ao nosso departamento
            jurídico, o que possibilita que qualquer pessoal, em qualquer lugar
            do mundo, possa receber atendimento jurídico de qualidade e em tempo
            real.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Através das nossas plataformas digitais, os clientes podem acessar
            seus processos, tirarem dúvidas, serem alertados sobre prazos e
            movimentações, e inclusive, acionar nosso departamento jurídico,
            para ajuizar qualquer demanda em seu favor, sem sair da sua casa.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Contamos com uma equipe altamente especializada em várias áreas do
            direito, sempre à disposição em atender e esclarecer qualquer
            dúvida.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Nossa empresa conta com longa experiência na atividade jurídica,
            onde tem como foco proporcionar as melhores soluções para as
            demandas, sempre prezando pela pessoalidade no desempenho da defesa
            dos interesses dos nossos clientes, inclusive em mediação de
            conflitos extrajudiciais.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Temos a concepção de que trabalhamos com pessoas, e não com
            processos, por isso, cada cliente recebe tratamento específico e
            individualizado, para que, atinjam a resolução da demanda com
            satisfação e consequentemente sejam o maior meio de propagação do
            nosso serviço.
          </Paragraph>
        </div>
      </Container>
    </SectionContent>
  </S.Section>
)

export default SectionBusinessModel
